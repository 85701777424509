import React from 'react'
import { graphql } from 'gatsby'

import BarTile from '../components/BarTile'

import Layout from '../layouts'

const metaDescription = (zone) => (
  `Les bars les moins chers autour de ${zone.name}.\
  Retrouvez tous les détails sur MisterGoodBeer.`
)

const metaKeywords = (zone) => (
  `${zone.name} pinte bière pas cher MisterGoodBeer`
)
const BarPageTemplate = ({ data, pathContext }) => {
  const { barsInZone } = data
  const { zone } = pathContext

  const selectedBarsInZone = barsInZone.edges || []

  return (
    <Layout
      seoProps={{
        url: `${zone.url}}`,
        title: `${zone.name} | Bar pas cher | MisterGoodBeer`,
        description: metaDescription(zone),
        keywords: metaKeywords(zone),
      }}
    >
      <section className="section">
        <div className="container">

          <h1 className="title is-1">
            {`Le top des bars autour de ${zone.name}`}
          </h1>

          {zone.description ? (
            <p>
              {zone.description}
            </p>
          ) : null}
          <br />

          <div className="columns is-multiline">
            { selectedBarsInZone
              .map((barNode) => <BarTile {...barNode.node} key={barNode.node.id} />)}
          </div>

        </div>
      </section>
    </Layout>
  )
}

export default BarPageTemplate

export const pageQuery = graphql`
  query BarInZone(
    $lowerLatitude: Float!,
    $upperLatitude: Float!,
    $lowerLongitude: Float!,
    $upperLongitude: Float!,
  ) {
    barsInZone: allMisterGoodBeerBar(
      filter: {
        location: {
          latitude: { gt: $lowerLatitude, lt: $upperLatitude},
          longitude: { gt: $lowerLongitude, lt: $upperLongitude},
        },
      },
      limit: 12,
      sort: {
        fields: [regularPrice, happyHourPrice]
        order: ASC
      },
    ) {
      edges {
        node {
          id
          slug
          name
          address
          address_details {
            vicinity
          }
          regularPrice
          happyHourPrice
          startHappyHour { iso }
          endHappyHour { iso }
        }
      }
    }
  }
`
